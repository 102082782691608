.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  text-align: center;
  z-index: 999;
  color: #000;
  background-color: rgba(255,255,255,0.7);
  font-size: 24px;
}
.loading .loadingSpinner {
  display: inline-block;
  margin: -0.4em 0.1em;
  width: 1.5em;
  height: 1.5em;
  background-size: 1.5em 1.5em;
}
.close {
  margin-right: 0.5em;
}
