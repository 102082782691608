.hidden {
  display: none;
}
.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
  height: 100%;
  width: 100%;
  background: rgba(208,208,208,0.6);
}
.errorBox {
  position: absolute;
  bottom: 0px;
  left: 0px;
  border: 1px solid #fd9a00;
  border-bottom: 0px;
  border-left: 0px;
  border-radius: 0px 3px 0px 0px;
  color: #fff;
  fill: #fff;
  background-color: #fd9a00;
  padding: 20px;
  width: 290px;
}
.explanation {
  margin-bottom: 10px;
}
