.userTableRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
}
.userTableRow .deleteIcon {
  display: none;
}
.userTableRow:hover .deleteIcon {
  display: initial;
  color: #000;
}
.userTableRow:hover .rowIcon {
  color: #000;
}
.rowIcon {
  color: #ccc;
  font-size: 22px;
  line-height: inherit;
  padding-left: 5px;
  opacity: 1;
}
.rowIcons {
  width: 60px;
  padding-left: 5px;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.avatarIcon {
  width: 32px;
  font-size: 32px;
  height: 32px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  color: #ccc;
  cursor: pointer;
  -ms-flex-item-align: center;
      align-self: center;
}
.emptyIcon {
  opacity: 0;
  line-height: 0px;
}
.userTableCell.userTableHeaderCell {
  border: none;
  border-bottom: 3px solid #4cbbff;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #666;
}
.userTableCell.userTableHeaderCell {
  background-color: #ccc;
  border-color: #ccc;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.userTableCell {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
  padding: 5px 10px;
  overflow: hidden;
  line-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.userTableCell input,
.userTableCell textarea,
.userTableCell div {
  outline: none;
  border: none;
  width: 100%;
}
.privateField {
  font-style: italic;
  color: #999;
}
.userTableCell:nth-child(5) {
  border-right: 1px solid #ccc;
}
.userTableCell.userTableHeaderCell:nth-child(5) {
  border-right: none;
}
.userTableCell.editDisabled {
  background-color: #ddd;
}
.userTableCell.editDisabled.privateField {
  color: #333;
}
.userTableCell.hyperlink input,
.userTableCell.hyperlink textarea,
.userTableCell.hyperlink div {
  color: #4a90e2;
  text-decoration: underline;
}
.userTableCell.preview {
  font-style: italic;
  color: #666;
}
.showMoreLink {
  color: #4a90e2;
}
@media (max-width: 1024px) {
  .userTableRow {
    margin: 10px 0px;
    display: grid;
    grid-template-columns: calc(90px + 20px) 1fr 1fr 60px;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas: "avatar name    bio buttons" "avatar email   bio buttons" "avatar website bio buttons" "avatar . bio buttons";
    background-color: #fff;
  }
  .userTableRow.editDisabled {
    background-color: #ddd;
  }
  .avatarIcon {
    margin-left: 20px;
    justify-self: center;
    grid-area: avatar;
    width: 90px;
    height: 90px;
    font-size: 90px;
  }
  .nameArea {
    grid-area: name;
    font-size: 1.3em;
  }
  .emailArea {
    grid-area: email;
  }
  .websiteArea {
    grid-area: website;
  }
  .websitePlainCell {
    padding: 4px 10px;
  }
  .bioArea {
    grid-area: bio;
  }
  .bioPlainCell {
    height: 100%;
  }
  .rowIcons {
    grid-area: buttons;
  }
  .userTableHeaderRow {
    display: none;
  }
  .userTableCell {
    padding: 0px 5px;
    border: none;
  }
  .userTableCell.userTableHeaderCell {
    display: none;
  }
  .userTableCell:nth-child(5) {
    border-right: none;
  }
  .deleteIcon {
    display: initial !important;
  }
}
