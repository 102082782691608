// override for original bootstrap styles
:global body .btn
  white-space normal
  word-wrap break-word
  // TODO: remove explicit margin here - in need of margin, use higher-level layout constructs like FormGroup
  margin 3px 0

  // styles for custom button types
  &.btn-custom-type-hint
    padding 0 0.2rem
    font-size 0.7rem
    border none
    // this one is needed only because of the temporary style for .btn
    margin 0

  &.btn-secondary
    color: #292b2c
    background-color: #fff
    border-color: #ccc