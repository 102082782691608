:global body .btn {
  white-space: normal;
  word-wrap: break-word;
  margin: 3px 0;
}
:global body .btn.btn-custom-type-hint {
  padding: 0 0.2rem;
  font-size: 0.7rem;
  border: none;
  margin: 0;
}
:global body .btn.btn-secondary {
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc;
}
