.permissionsList {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.permissionsEntry {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.permissionsEntry > * {
  margin-bottom: 10px;
}
.addPermissionIcon {
  margin-right: 10px;
}
