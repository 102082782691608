// TODO(felipap): turn links created as <div /> with onClick into actual links
// or buttons.

.modalContent
  background: rgba(0, 0, 0, .6) !important
  color: white

.modalCloseButton
  flex-direction: column
  align-items: flex-end
  height: 40px
  display: flex
  padding: 30px 0

.navigationMenu
  font-family: sans-serif
  padding: 0 20px

  .tabLinks
    display: flex
    flex-direction: column
    align-items: center
    border-bottom: 1px solid rgba(255,255,255,.4)
    padding-bottom: 15px

    .tabLink
      font-size: 20px
      margin-top: 20px
      cursor: pointer
      color: white

      &:not(.tabLinkCurrent):hover
        text-decoration none

    .tabLinkCurrent
      font-weight: 800
      text-decoration: underline

    .tabLinkIcon
      margin-right: 10px
      font-size: 20px

  .dropdownEquivalent
    text-transform: uppercase
    align-items: center
    display: flex
    flex-direction: column

    .signInAction
      color: #44ddff !important
      font-style: italic

    .logOutAction
      color: #ff8888 !important
      font-style: italic

    .navLink
      cursor: pointer
      font-size: 17px
      display: block
      margin-top: 20px
      color white

      &:hover
        text-decoration: none
