.nodeDelete {
  background-image: url("./icons/nodeDelete.svg");
}
.nodeHide {
  background-image: url("./icons/nodeHide.svg");
}
.nodeIsolate {
  background-image: url("./icons/nodeIsolate.svg");
}
.nodeExpand {
  background-image: url("./icons/nodeExpand.svg");
}
.nodeExpand2 {
  background-image: url("./icons/nodeExpand2.svg");
}
.nodeCollapse {
  background-image: url("./icons/nodeCollapse.svg");
}
.zoomEntireGraph {
  background-image: url("./icons/zoomEntireGraph.svg");
}
.graphExpand {
  background-image: url("./icons/graphExpand.svg");
}
.graphExpand2 {
  background-image: url("./icons/graphExpand2.svg");
}
.edgeLabelsHide {
  background-image: url("./icons/edgeLabelsHide.svg");
}
.edgeLabelsShow {
  background-image: url("./icons/edgeLabelsShow.svg");
}
.graphEvolveFull {
  background-image: url("./icons/graphEvolveFull.svg");
}
.graphEvolveStart {
  background-image: url("./icons/graphEvolveStart.svg");
}
.graphEvolveStop {
  background-image: url("./icons/graphEvolveStop.svg");
}
.graphHideAll {
  background-image: url("./icons/graphHideAll.svg");
}
.undo {
  background-image: url("./icons/undo.svg");
}
.modeMove {
  background-image: url("./icons/modeMove.svg");
}
.modeConnect {
  background-image: url("./icons/modeConnect.svg");
}
.modeMerge {
  background-image: url("./icons/modeMerge.svg");
}
.zoomIn {
  background-image: url("./icons/zoomIn.svg");
}
.zoomOut {
  background-image: url("./icons/zoomOut.svg");
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.timer {
  background-image: url("./icons/timer.svg");
  -webkit-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}
.download {
  background-image: url("./icons/download.svg");
}
.move {
  background-image: url("./icons/move.svg");
}
.in {
  background-image: url("./icons/in.svg");
}
.filter {
  background-image: url("./icons/filter.svg");
}
.closeButton {
  background-image: url("./icons/closeButton.svg");
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
