.permissionDetails
  display: flex
  flex-grow: 1
  align-items: center
  justify-content: flex-end

  > *
    margin-left: 10px

.spacer
  flex-grow: 1

.permissionSelect
  min-width: 150px
  display: inline-block

.permissionOwner
  > input
    margin-right: 5px

  margin-bottom: 0