.valign {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ideaPopover {
  max-width: 400px;
}
.ideaPopoverInner {
  max-width: 400px;
}
.changeLogTitle {
  font-size: 20px;
  padding: 15px 0px;
  font-weight: normal;
}
.changeLogHeader {
  font-weight: bold;
}
.changelog {
  width: 100%;
  background: #f0f5fc;
}
.changeLogRow {
  margin-bottom: 0px;
  padding: 11px 5px;
  border-bottom: 2px solid rgba(0,0,0,0.2);
  border-radius: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr 0.5fr 0.5fr 2fr;
  grid-template-rows: repeat(6, auto);
  grid-template-areas: "timestamp user contextTop old arrow arrow new" "timestamp user contextBot old arrow arrow new";
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  justify-items: stretch;
  align-items: stretch;
  justify-items: stretch;
}
.changeLogRowOneChange {
  grid-template-columns: 1fr 1fr 2fr 5fr;
  grid-template-rows: repeat(3, auto);
  grid-template-areas: "timestamp user contextTop change" "timestamp user contextBot change";
}
.changeLogRowHeader {
  font-weight: bold;
  padding: 0px;
  margin-bottom: 0px;
  border: none;
  grid-template-areas: "dayHeader userHeader contextHeader oldHeader oldHeader newHeader newHeader";
}
.spanAll {
  grid-area: 1/1 /Infinity/Infinity;
}
.dayHeader {
  grid-area: dayHeader;
}
.userHeader {
  grid-area: userHeader;
}
.timestamp {
  grid-area: timestamp;
  -ms-flex-item-align: center;
      align-self: center;
}
.user {
  grid-area: user;
  -ms-flex-item-align: center;
      align-self: center;
}
.change {
  grid-area: change;
  -ms-flex-item-align: center;
      align-self: center;
}
.arrow {
  -webkit-transform: scaleX(4);
          transform: scaleX(4);
  color: #808080;
  grid-area: arrow;
  -ms-flex-item-align: center;
      align-self: center;
  justify-self: center;
}
.contextHeader {
  grid-area: contextHeader;
}
.contextTop {
  font-style: italic;
  grid-area: contextTop;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.contextBot {
  grid-area: contextBot;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ideaShorthandIdea {
  color: #2f80ed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.connectionShorthandIdea {
  color: #2f80ed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.oldHeader {
  grid-area: oldHeader;
}
.newHeader {
  grid-area: newHeader;
}
.change {
  grid-area: change;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 60px;
}
.old {
  grid-area: old;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 60px;
}
.new {
  grid-area: new;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
