.contributorsViewWrapper {
  background-color: #f7f7f7;
  min-height: 100vh;
}
.contributorsView {
  margin: 0 5%;
  height: 100%;
}
@media (max-width: 1024px) {
  .contributorsView {
    margin: 0 0%;
  }
}
