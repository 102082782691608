// ----------------------------------------------------------------------------
// Mixins
// ----------------------------------------------------------------------------

border-radius()
  -webkit-border-radius arguments
  -moz-border-radius arguments
  -ms-border-radius arguments
  -o-border-radius arguments
  border-radius arguments

box-shadow()
  -webkit-box-shadow arguments
  -moz-box-shadow arguments
  -ms-box-shadow arguments
  -o-box-shadow arguments
  box-shadow arguments

break-word()
  -ms-word-break break-all
  word-break break-all
  // Non standard for webkit
  word-break: break-word;
  -webkit-hyphens auto
  -moz-hyphens auto
  hyphens auto

word-wrap()
  white-space pre-wrap      /* CSS3 */
  white-space -moz-pre-wrap /* Firefox */
  white-space -pre-wrap     /* Opera <7 */
  white-space -o-pre-wrap;  /* Opera 7 */
  word-wrap break-word      /* IE */

ellipsis()
  white-space nowrap
  overflow hidden
  -ms-text-overflow ellipsis
  text-overflow ellipsis

ellipsis-long()
  white-space prewrap
  overflow hidden
  -ms-text-overflow ellipsis
  text-overflow ellipsis

inline-block()
  display -moz-inline-stack
  display inline-block
  zoom 1
  *display inline

pie()
  behavior url('/commonStyles/PIE.htc')

box-style()
  box-shadow(0 1px 1px #cbcbcb)
  background-color white
  border-top 1px solid #f0f0f0
