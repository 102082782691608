.contentsWrapper {
  position: relative;
}
.contentsWrapper .inlineMenuWrapper {
  display: none;
  z-index: 999;
  position: absolute;
  height: 50%;
  margin: auto;
  right: 0;
  top: 0;
  bottom: 0;
}
.contentsWrapper:hover .inlineMenuWrapper {
  display: inline-block;
}
