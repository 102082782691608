.contentsWrapper
  position relative

  .inlineMenuWrapper
    display none
    z-index 999

    //make it 'float' on the right edge centered vertically
    position absolute
    height 50%
    margin auto
    right 0
    top 0
    bottom 0


  &:hover
    .inlineMenuWrapper
      display inline-block

