@import "~commonStyles/textfield.styl"
@import "~commonStyles/variables.styl"

.container
  // the search bar can be displayed in an overlay with disabled pointer-events,
  // so need to ensure normal events inside the search bar
  pointer-events auto
  width 100%

.loadingIcon
  width: 1em
  height: 1em
  align-self: center

.searchInputContainer
  @extends .textfield
  display flex
  width 100%
  font-size 16px
  line-height 30px
  background transparent
  text-align left
  text-shadow none
  position relative
  padding-bottom 2px

  &.bareContainer
    font-size: inherit
    line-height: inherit
    min-height: auto

.regular
  .searchInputContainer
    background #fff
    color #666

.unstyled
  .searchInputContainer
    background inherit
    color inherit
    border none

.searchInput
  box-sizing: border-box
  flex 1 1
  height: 32px
  line-height: 30px
  width 100%
  border none
  outline none
  color inherit
  background transparent
  word-wrap: unset
  resize: none
  white-space: nowrap

  &.multiline
    line-height: inherit
    height: inherit
    white-space: pre-wrap
    max-height: 300px

    // :placeholder-shown for UniversalTextInput
    &:empty
      line-height: 32px
      height: 32px
      min-height: 32px
      overflow: hidden

  &.bareInput
    line-height: inherit
    height: inherit

.searchBarXButton
  flex 0 0
  line-height 30px
  width 42px
  text-align center
  font-size 24px
  color #aaa
  font-family Arial, Sans-Serif
  background transparent
  opacity 0.6
  cursor pointer
  position relative

.autocompleteWrapper
  font-size 16px
  line-height 30px
  cursor pointer
  box-shadow(0 2px 4px rgba(0,0,0,0.2))
  border 1px solid color-lighter-gray
  border-top none
  background-color #fff

  .controls
    background-color: #ebebeb

  .addRelatedButton
    background-color: color-lightest-gray
    border: 1px solid color-lighter-gray
    font-size: 12px
    line-height: inherit
    margin: 5px 15px 5px

    &:hover
      background-color: color-lighter-gray

    &:active, &:focus
      background-color: #bbb


.searchResult
  box-sizing: border-box
  height 30px
  border none
  padding-left 14px
  padding-right 14px
  overflow hidden
  white-space nowrap
  text-overflow ellipsis
  font-family sans-serif
  border-top 1px solid #eee

  background-color: white
  color: #666

.selectedSearchResult
  background-color: #89c7e4
  color: white
  color: #666

.header
  background-color: color-light-gray
  color: white

.match
  background-color #646464
  color white

.recentSearchesHeader {
  padding-left 14px
  padding-right 14px
  font-size: 11px
  text-transform: uppercase
  background-color: #eee
  color: #999
  border-bottom: 1px solid color-lightest-gray
}
