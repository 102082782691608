.userGridWrapper
  display: flex
  flex-direction: column
  align-items: center

.showAnonymousUsersCheckbox
  margin-left: 5px !important

.userGrid
  display: flex
  flex-wrap: wrap
  justify-content: center

.box
  margin-right: 25px
  margin-left: 25px
  margin-bottom: 40px
