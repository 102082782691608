@import "~commonStyles/variables.styl"

.boardsList
  background-color: color-wrapper-background
  min-height: 100vh

.boardsListBody
  display: flex
  flex-direction: column
  max-width: 1000px
  margin: 0 auto
  padding-top: 60px
  height 100%

.title
  font-size: 20px
  margin-bottom: 10px
