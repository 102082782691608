.rounded-corners,
.button,
.facebookButton,
.submitButton {
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.bordered {
  border: 1px solid #ccc;
}
.button,
.facebookButton,
.submitButton {
  background: #ddd;
  border: none;
  margin: 2px 0px;
  padding: 10px 12px;
  color: #111;
  -webkit-box-shadow: inset 0 -2px rgba(0,0,0,0.2);
  -ms-box-shadow: inset 0 -2px rgba(0,0,0,0.2);
  -o-box-shadow: inset 0 -2px rgba(0,0,0,0.2);
  box-shadow: inset 0 -2px rgba(0,0,0,0.2);
  cursor: pointer;
}
.button[disabled],
.facebookButton[disabled],
.submitButton[disabled] {
  opacity: 0.5;
}
.joinViewWrapper {
  background-color: #f7f7f7;
  min-height: 100vh;
}
.joinView {
  margin: 0 5%;
  height: 100%;
}
.joinForm {
  width: 100%;
}
h5 {
  margin-bottom: 15px;
}
.facebookButton {
  width: 400px;
  max-width: 100%;
  font-weight: bold;
  color: #fff;
  background-color: #4a90e2;
  margin-bottom: 15px;
}
.submitButton {
  width: 100%;
  font-weight: bold;
  color: #fff;
  background-color: #2ab27b;
  margin-top: 10px;
}
.interestInput {
  margin-bottom: 25px;
  text-overflow: ellipsis;
}
.ideaInput {
  margin-bottom: 10px;
}
@media (max-width: 480px) {
  .facebookButton {
    width: 100%;
  }
}
