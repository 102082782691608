
@import "~commonStyles/variables.styl"

.header
  background-color: #333
  display: flex
  font-size: 14px
  justify-content: space-between
  color: #fff
  font-family: sans-serif
  align-items: center

  .viewTypeTab,
  .viewTypeTab.current,
  .inlineIcon
    color: #fff !important

  & > div
    flex: 0

  & > .boardPath
    flex: 1

.boardPath
  display: flex
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  justify-content: flex-start

  font-weight: 300
  align-items: center
  line-height: 1.3

  .boardPathLink
    font-family: sans-serif
    color: white
    padding: 0 10px

    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

    &:hover
      text-decoration: underline !important

  .boardPathParentLink
    background black
    height 50px
    width 50px
    
    display flex
    align-items center
    justify-content center

  .boardPathIcon
    margin: 0px 10px
    color: #aaa

.menuButtons
  flex: 0
  display: flex
  align-items: center
  position: relative
  margin-right: 10px

  .loginLink
    color: #33ddff
    font-weight: 400
    white-space: nowrap
    margin-right: 15px

.rootLink
  font-size: 17px
  padding: 5px 10px
  background: #0bf
  line-height: 40px
  font-weight: 600
  color: white