@import '~commonStyles/mixins.styl'

.loading
  position: fixed
  width: 100%
  height: 100%

  display: flex
  flex-direction: column
  justify-content: center
  align-content: center

  text-align: center
  z-index: 999
  color: black
  background-color: rgba(255, 255, 255, 0.7)
  font-size 24px

  .loadingSpinner
    display inline-block
    margin -0.4em 0.1em
    width 1.5em
    height 1.5em
    background-size: 1.5em 1.5em

.close
  margin-right: 0.5em
