@import "~commonStyles/variables.styl"

.universalTextInput
  white-space: pre-wrap
  overflow: hidden

.universalTextInputSingleLine
  // Vertically align single line of text
  display: flex
  align-items: center
  white-space: nowrap

.universalTextInput[contentEditable=true]:empty:before {
  content: attr(data-placeholder)
  color: color-light-gray
}