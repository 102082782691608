@import "./normalize.css";
@import '../media/fonts/fonts.css';
a {
  cursor: pointer;
  color: #4a90e2;
}
button {
  cursor: pointer;
}
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'AvenirNextLTW01-Medium', Aria, sans-serif;
}
input {
  font-family: 'AvenirNextLTW01-Medium', Aria, sans-serif;
}
