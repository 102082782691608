.userProfileBox {
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-width: 3px;
  width: 250px;
  padding: 15px;
  min-height: 500px;
}
.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}
.fields {
  width: 100%;
}
.avatar {
  height: 110px;
  width: 110px;
  font-size: 110px !important;
  color: #ddd;
  margin: 10px;
}
.avatar:hover {
  color: #ccc;
}
.name {
  font-size: 20px;
  margin-top: 10px;
  color: #333;
}
.label {
  color: #aaa;
  text-transform: uppercase;
  margin-top: 10px;
  font-size: 12px;
}
.field,
.bio {
  font-size: 14px;
  color: #333;
}
.bio {
  height: 100px;
  overflow: auto;
  word-break: break-word;
}
.missingField,
.missingBioField {
  font-size: 14px;
  color: #ccc;
  font-style: italic;
}
.missingBioField {
  height: 100px;
}
.privateField {
  font-style: italic;
  color: #666;
}
.checkboxLabel {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.checkboxLabel input {
  margin-right: 10px;
  -ms-flex-item-align: center;
      align-self: center;
}
.input,
.textarea {
  border: 1px solid #cbcbcb;
  padding: 2px 5px;
  font-size: 14px;
  color: #666;
  margin-top: 5px;
  width: 100%;
}
.textarea {
  height: 100px;
  resize: none;
}
.links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}
.links .editLink {
  border-right: 1px solid #aaa;
  padding: 0 5px;
}
.links .editLink:first-child {
  padding-left: 0;
}
.editLink {
  font-size: 12px;
  text-transform: uppercase;
  color: #aaa;
  text-decoration: underline;
  cursor: pointer;
}
.editLink:hover {
  color: #333;
}
.editLink:last-child {
  border-right: none;
}
.error {
  color: #f00;
}
@media (max-width: 1024px) {
  .userProfileBox {
    min-width: 250px;
    max-width: 300px;
    padding: 15px;
    width: 85vw;
  }
}
