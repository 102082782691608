@import "common.styl"

/*TODO: replace usage leftovers with components/common/Button and delete these styles*/
.button
  @extend .rounded-corners
  background #ddd
  border none
  margin 2px 0px
  padding 10px 12px
  color #111
  box-shadow(inset 0 -2px rgba(0,0,0,0.2))
  cursor pointer

  &[disabled]
    opacity 0.5