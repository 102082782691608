.singleColumnPage,
.pageBody {
  background: #fff;
  border: 1px solid #d6d6d6;
  padding: 5px;
  padding: 45px 60px 10px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 auto 0 auto;
}
.pageBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
table.usersTable,
table.usersTable th,
table.usersTable td {
  border: 1px solid;
}
table.usersTable {
  border-collapse: collapse;
  width: 100%;
}
table.usersTable th {
  margin: 5px;
}
table.usersTable td {
  background-color: #fafafa;
}
table.usersTable td,
table.usersTable th {
  padding: 5px;
}
.flagCell {
  text-align: center;
}
