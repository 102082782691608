.header,
.desktopHeader {
  background-color: #fafafa;
  --highlight-color: #4a90e2;
}
.header .dropdown,
.desktopHeader .dropdown {
  background: #fafafa;
}
.desktopHeader {
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  line-height: 1;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.boardPath {
  padding-left: 15px;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  font-size: 20px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1.3;
}
.boardPath .boardLink:last-child {
  color: inherit;
}
.boardPath:hover .expandedBoardPath {
  display: block;
}
.boardPath:hover .shortBoardPath {
  display: none;
}
.expandedBoardPath {
  background-color: #fff;
  border: 1px solid #ccc;
  display: none;
  padding: 10px;
  padding-left: 8px;
  margin-left: -9px;
  margin-top: -1px;
}
.shortBoardPath {
  padding: 10px;
  padding-left: 0px;
}
.boardLink {
  color: #aaa;
}
.boardLink:hover {
  text-decoration: underline !important;
}
.boardLinkMinimal {
  color: #fff;
}
.boardPathIcon {
  margin: 0px 10px;
  color: #aaa;
}
.rootLink {
  padding: 10px 0;
}
@media (max-width: 1024px) {
  .rootLink {
    padding-left: 15px;
  }
}
.expander {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
}
.searchBar {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  min-width: 100px;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px 10px;
}
.navLinkWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.dropdown {
  position: absolute;
  z-index: 3;
  top: 100%;
  -webkit-box-shadow: 0 1px 1px #cbcbcb;
          box-shadow: 0 1px 1px #cbcbcb;
}
.inlineIcon {
  margin: 0px 10px 0px 15px;
  font-size: 16px !important;
}
.dropdownIcon {
  margin: 0px 10px 0px 15px;
  font-size: 26px !important;
  width: 26px;
  height: 26px;
}
.dropdownIcon:hover,
.dropdownIcon.selected {
  color: var(--highlight-color) !important;
}
.dropdownIcon.menuIcon {
  margin-left: 10px;
}
.navLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  padding: 15px 15px;
  height: 54px;
  line-height: 24px;
  color: #212529;
}
.navLink:hover,
.navLink.selected {
  text-decoration: none;
  color: var(--highlight-color) !important;
}
.viewTypeWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  white-space: nowrap;
  color: #333;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
}
.viewTypeTab {
  color: #666;
  text-align: right;
  padding-left: 0px;
  padding-right: 15px;
}
.viewTypeTab:hover {
  color: var(--highlight-color) !important;
}
.viewTypeTab.current {
  color: #000;
  font-weight: 600;
}
.menu {
  width: 150px;
  right: 0;
}
.userLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 10px;
  padding: 15px 15px;
  height: 54px;
  line-height: 24px;
}
.userLink .thinCopy {
  opacity: 0.25;
  font-weight: 200;
}
.userLink .navLink {
  padding: 0px 5px;
}
