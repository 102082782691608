.rounded-corners,
.button,
.loginButton,
.facebookButton {
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.bordered {
  border: 1px solid #ccc;
}
.button,
.loginButton,
.facebookButton {
  background: #ddd;
  border: none;
  margin: 2px 0px;
  padding: 10px 12px;
  color: #111;
  -webkit-box-shadow: inset 0 -2px rgba(0,0,0,0.2);
  -ms-box-shadow: inset 0 -2px rgba(0,0,0,0.2);
  -o-box-shadow: inset 0 -2px rgba(0,0,0,0.2);
  box-shadow: inset 0 -2px rgba(0,0,0,0.2);
  cursor: pointer;
}
.button[disabled],
.loginButton[disabled],
.facebookButton[disabled] {
  opacity: 0.5;
}
.modal {
  width: 700px;
  padding-bottom: 55px;
  margin: 0;
  max-width: 100%;
}
.emailSignupModal {
  padding-top: 10px;
}
.header {
  font-weight: bold;
}
.loginForm {
  padding: 20px 50px 0px 50px;
  overflow: auto;
}
.loginInput {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 16px;
  border: solid 1px #b3b3b3;
  border-radius: 2px;
  display: block;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
  max-width: 500px;
  color: #000;
  padding: 10px 11px;
  height: 44px;
}
.emailSignupInput {
  max-width: none;
  margin-bottom: 5px;
}
.passwordLoginInput {
  max-width: none;
}
.loginButton {
  width: 100%;
  font-weight: bold;
  color: #fff;
  background-color: #2ab27b;
  margin-top: 10px;
}
.facebookButton {
  width: 100%;
  font-weight: bold;
  color: #fff;
  background-color: #4a90e2;
  margin-top: 15px;
  margin-bottom: 15px;
}
.otherOptions {
  position: relative;
  padding-top: 10px;
  text-align: center;
  font-size: 14px;
}
.splitLeftInput {
  float: left;
  width: 49%;
  margin-right: 1%;
}
.splitRightInput {
  float: right;
  margin-left: 1%;
  width: 49%;
}
.toggleText {
  float: right;
  overflow: auto;
}
.backButton {
  margin-left: 10px;
}
.error {
  color: #f00;
}
.accountMergeMessage {
  clear: both;
  margin-top: 15px;
  color: #666;
  padding-bottom: 5px;
}
.accountMergeMessage span {
  color: #4a90e2;
}
.radioButtonContainer {
  margin-right: 10px;
  display: inline-block;
}
[type="radio"] {
  margin-right: 5px;
}
