.deleteButton {
  padding-right: 5px;
  text-align: center;
  font-size: 22px;
  color: #aaa;
  font-family: Arial, Sans-Serif;
  background: transparent;
  opacity: 0.6;
  cursor: pointer;
}
.deleteButton:hover {
  opacity: 1;
}
.linkOption {
  font-size: 14px;
  padding-left: 12px;
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}
.confirmMessage {
  font-size: 14px;
  color: #fff;
}
.buttonWrapper {
  background: #ffa500;
  padding: 6px;
  border-radius: 7px;
  display: inline-block;
  background: #ffa500;
  padding: 6px;
  border-radius: 7px;
  display: inline-block;
  z-index: 10;
}
