.boardsList {
  background-color: #f7f7f7;
  min-height: 100vh;
}
.boardsListBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 60px;
  height: 100%;
}
.title {
  font-size: 20px;
  margin-bottom: 10px;
}
