@import '~commonStyles/button.styl'

.joinViewWrapper
  background-color: color-wrapper-background
  min-height: 100vh

.joinView
  margin: 0 5%
  height: 100%

.joinForm
  width: 100%

h5
  margin-bottom 15px

.facebookButton
  @extend .button
  width 400px
  max-width 100%
  font-weight bold
  color white
  background-color color-blue
  margin-bottom: 15px

.submitButton
  @extend .button
  width 100%
  font-weight bold
  color white
  background-color color-green
  margin-top: 10px

.interestInput
  margin-bottom: 25px
  text-overflow: ellipsis

.ideaInput
  margin-bottom: 10px

@media(max-width: mobile-max-width-small)
  .facebookButton
    width 100%
