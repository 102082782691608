.modalWrapper {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.modalContent {
  max-height: 90vh;
  max-width: 90vw;
  overflow: auto;
}
.modalContentFixedSize {
  height: 80vh !important;
  width: 80vw !important;
}
:global(.modal-dialog) {
  max-width: initial !important;
}
:global(.modal-body) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
:global(.modal-dialog.modal-full) {
  width: 100% !important;
  max-width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
:global(.modal-dialog.modal-full) :global(.modal-content) {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100% !important;
  max-height: 100% !important;
  border-radius: 0;
  border: 0;
}
:global(.modal-header),
:global(.modal-footer) {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
:global(.modal-body) {
  overflow: auto;
}
