@import "~commonStyles/variables.styl"

.userTable:focus
  outline: none
  
.userTable
  margin-bottom: 50px

.windowScrollerWrapper
  flex: 1 1 auto

.stickyUserTableRows
  padding-top: 20px
  position: sticky
  z-index: z-index-sticky
  top: 'calc(%s - 1px)' % header-height
  background-color: color-content

@media(max-width: mobile-max-width)
  .stickyUserTableRows
    top: 0px
    padding-top: 0px
