@import "~commonStyles/singleColumnPage"

.pageBody
  display: flex
  flex-direction: column
  @extend .singleColumnPage

table.usersTable
  &, th, td
    border 1px solid
  &
    border-collapse collapse
    width 100%

  th
    margin 5px
  td
    background-color #FAFAFA

  td, th
    padding 5px

.flagCell
  text-align center
