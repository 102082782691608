@import "~commonStyles/variables.styl"

.contributorsViewWrapper
  background-color: color-wrapper-background
  min-height: 100vh

.contributorsView
  margin: 0 5%
  height: 100%

@media (max-width: mobile-max-width)
  .contributorsView
    margin: 0 0%