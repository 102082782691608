
@import "~commonStyles/variables.styl"

.header
  background-color header-background-color
  --highlight-color: color-blue

  .dropdown
    background: header-background-color

.desktopHeader
  @extends .header
  height header-height
  display flex
  flex-direction row
  justify-content space-between
  align-items center
  padding 0
  line-height 1

.row
  display flex
  flex-direction row
  align-items center

.boardPath
  padding-left 15px
  flex 0 1 auto
  font-size 20px
  margin-right 10px
  display flex
  align-items center
  line-height 1.3

  .boardLink:last-child
    color inherit

.boardPath:hover
  .expandedBoardPath
    display block

  .shortBoardPath
    display none

.expandedBoardPath
  background-color white
  border 1px solid color-lighter-gray
  display none
  padding 10px
  padding-left 8px
  margin-left -9px // compensate for border+padding
  margin-top -1px // compensate for border

.shortBoardPath
  padding 10px
  padding-left 0px

.boardLink
  color #aaa
  &:hover
    text-decoration underline !important

.boardLinkMinimal
  color: white

.boardPathIcon
  margin 0px 10px
  color #aaa

.rootLink
  padding: 10px 0
  @media(max-width: mobile-max-width)
    padding-left: 15px

.expander
  flex 1 1 0

.searchBar
  flex 1 1 auto
  min-width 100px
  height header-height
  display flex
  align-items center
  margin 0px 10px

.navLinkWrapper
  display flex
  flex none
  align-items center
  position relative

.dropdown
  position absolute
  z-index z-index-dropdown
  top 100%
  box-shadow 0 1px 1px #cbcbcb

.inlineIcon
  margin 0px 10px 0px 15px
  font-size 16px !important

dropdownWidth = 26px
.dropdownIcon
  margin 0px 10px 0px 15px
  font-size dropdownWidth !important
  width: dropdownWidth
  height: dropdownWidth

  &:hover, &.selected
    color var(--highlight-color) !important

.dropdownIcon.menuIcon
  margin-left: 10px

.navLink
  display: flex
  align-items: center
  cursor pointer
  padding 15px 15px
  height 54px
  line-height 24px
  color: bootstrap-body-color

  &:hover, &.selected
    text-decoration none
    color var(--highlight-color) !important

.viewTypeWrapper
  display flex
  flex-direction row
  white-space nowrap
  color #333
  flex none

.viewTypeTab
  color color-dark-gray
  text-align right
  padding-left 0px
  padding-right 15px

  &:hover
    color var(--highlight-color) !important

.viewTypeTab.current
  color black
  font-weight 600

.menu
  width 150px
  right 0

.userLink
  display flex
  align-items center
  margin-left 10px
  padding 15px 15px
  height 54px
  line-height 24px

  .thinCopy
    opacity 0.25
    font-weight 200

  .navLink
    padding 0px 5px
