@import "~commonStyles/variables.styl"

.board
  padding: 10px 15px
  margin-bottom: 15px
  border: 1px solid #ddd
  border-bottom-width: 3px
  background-color: white

  &:hover
    background: #fcfcfc

    .linkContainer
      opacity: 1

.title
  cursor: pointer

  &:hover
    color: color-blue

.ideaCount
  font-size: 12px
  color: #aaa
  margin-left: 5px

.linkContainer
  margin-left: 5px
  opacity: 0

.link
  padding: 0 5px
  font-size: 12px
  text-transform: uppercase
  color: #aaa
  text-decoration: underline
  border-right: 1px solid #aaa
  cursor: pointer

  &:hover
    color: #333

  &:last-child
    border-right: none

.childrenToggle
  margin-top: 5px
  font-size: 12px
  text-decoration: underline
  color: #aaa
  margin-bottom: 10px
  cursor: pointer

  &.expanded
    color: color-blue

  &:hover
    color: #333

    &.expanded
      color: color-blue

