.colorBox {
  width: 200px;
  margin: 10px 0;
}
.colorLine {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.square {
  display: inline-block;
  vertical-align: middle;
  width: 17px;
  height: 17px;
  cursor: pointer;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  border-width: 2px;
}
.isNotSelectable {
  cursor: initial;
}
.theSelectedColor {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isUsed {
  background-size: 17px 17px;
  background-image: url("../../media/icons/checkWhite.svg");
}
