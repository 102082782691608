.modalContent {
  background: rgba(0,0,0,0.6) !important;
  color: #fff;
}
.modalCloseButton {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 0;
}
.navigationMenu {
  font-family: sans-serif;
  padding: 0 20px;
}
.navigationMenu .tabLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(255,255,255,0.4);
  padding-bottom: 15px;
}
.navigationMenu .tabLinks .tabLink {
  font-size: 20px;
  margin-top: 20px;
  cursor: pointer;
  color: #fff;
}
.navigationMenu .tabLinks .tabLink:not(.tabLinkCurrent):hover {
  text-decoration: none;
}
.navigationMenu .tabLinks .tabLinkCurrent {
  font-weight: 800;
  text-decoration: underline;
}
.navigationMenu .tabLinks .tabLinkIcon {
  margin-right: 10px;
  font-size: 20px;
}
.navigationMenu .dropdownEquivalent {
  text-transform: uppercase;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.navigationMenu .dropdownEquivalent .signInAction {
  color: #4df !important;
  font-style: italic;
}
.navigationMenu .dropdownEquivalent .logOutAction {
  color: #f88 !important;
  font-style: italic;
}
.navigationMenu .dropdownEquivalent .navLink {
  cursor: pointer;
  font-size: 17px;
  display: block;
  margin-top: 20px;
  color: #fff;
}
.navigationMenu .dropdownEquivalent .navLink:hover {
  text-decoration: none;
}
