@import "~commonStyles/variables.styl"

.outer
  display: flex
  flex-direction: column
  height: auto
  background-color: color-background
  min-height: 100vh

.headerWrapper
  position: sticky
  top: 0
  left: 0
  width: 100%
  z-index: z-index-header-wrapper

.inner
  position: relative
  display: flex
  flex-grow: 1

.content
  max-width 100%
  position: relative
  flex-grow: 1
  background-color: color-content

.sidebarLayout
  position: relative
  display: flex
  flex-direction: row
  height: 100%

.mainPanel
  flex-grow: 1

@media (max-width: mobile-max-width)
  .headerWrapper
    position: relative