.header {
  background-color: #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #fff;
  font-family: sans-serif;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header .viewTypeTab,
.header .viewTypeTab.current,
.header .inlineIcon {
  color: #fff !important;
}
.header > div {
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
}
.header > .boardPath {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.boardPath {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-weight: 300;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1.3;
}
.boardPath .boardPathLink {
  font-family: sans-serif;
  color: #fff;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.boardPath .boardPathLink:hover {
  text-decoration: underline !important;
}
.boardPath .boardPathParentLink {
  background: #000;
  height: 50px;
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.boardPath .boardPathIcon {
  margin: 0px 10px;
  color: #aaa;
}
.menuButtons {
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin-right: 10px;
}
.menuButtons .loginLink {
  color: #3df;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 15px;
}
.rootLink {
  font-size: 17px;
  padding: 5px 10px;
  background: #0bf;
  line-height: 40px;
  font-weight: 600;
  color: #fff;
}
