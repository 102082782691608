@import "~commonStyles/variables.styl"

.usersList
  background-color: color-background-wrapper
  min-height: 100vh

.usersListBody
  max-width: 1000px
  margin: 0 auto
  padding-top: 60px
  height 100%
