@import "~commonStyles/variables.styl"

.userProfileBox
  background-color: white
  border: 1px solid color-lightest-gray
  border-bottom-width: 3px
  width: 250px
  padding: 15px
  min-height: 500px

.content
  display: flex
  flex-direction: column
  align-items: center

.header
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 30px
  cursor: pointer

.fields
  width: 100%

avatarSize = 110px
.avatar
  height: avatarSize
  width: avatarSize
  font-size: avatarSize !important
  color: color-lightest-gray
  margin: 10px

  &:hover
    color: color-lighter-gray

.name
  font-size: 20px
  margin-top: 10px
  color: color-darker-gray

.label
  color: color-light-gray
  text-transform: uppercase
  margin-top: 10px
  font-size: 12px

.field
  font-size: 14px
  color: color-darker-gray

.bio
  @extends .field
  height: 100px
  overflow: auto
  word-break: break-word

.missingField
  font-size: 14px
  color: color-lighter-gray
  font-style: italic

.missingBioField
  @extends .missingField
  height: 100px

.privateField
  font-style: italic
  color: color-dark-gray

.checkboxLabel
  font-size: 12px
  color: color-medium-gray
  margin-top: 5px
  display: flex

  input
    margin-right: 10px
    align-self: center

.input
  border: 1px solid #cbcbcb
  padding: 2px 5px
  font-size: 14px
  color: color-dark-gray
  margin-top: 5px
  width: 100%

.textarea
  @extends .input
  height: 100px
  resize: none

.links
  display: flex
  margin-top: 20px

  .editLink
    border-right: 1px solid color-light-gray
    padding: 0 5px
      
    &:first-child
      padding-left: 0

.editLink
  font-size: 12px
  text-transform: uppercase
  color: color-light-gray
  text-decoration: underline
  cursor: pointer

  &:hover
    color: color-darker-gray

  &:last-child
    border-right: none

.error
  color: color-error

@media(max-width: mobile-max-width)
  .userProfileBox
    min-width: 250px
    max-width: 300px
    padding: 15px
    width: 85vw
