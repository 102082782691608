@font-face {
  font-family: "AvenirNextLTW01-DemiCn";
  src: url("./avenir/fe4d96a8-3161-4f0c-ac51-ed6a5df15918.eot?#iefix");
  src: url("./avenir/fe4d96a8-3161-4f0c-ac51-ed6a5df15918.eot?#iefix") format("eot"), url("./avenir/add96dac-4679-4981-9390-74fd5a5bf61f.woff") format("woff"), url("./avenir/60f1dfd0-eea9-448c-84a0-e4e8d5ba7516.ttf") format("truetype"), url("./avenir/f9659813-f8c7-4548-9117-b8e02fa26641.svg#f9659813-f8c7-4548-9117-b8e02fa26641") format("svg");
}
@font-face {
  font-family: "Avenir Next W01 Light";
  src: url("./avenir/0ecef829-586e-426d-a633-4c8a8c926769.eot?#iefix");
  src: url("./avenir/0ecef829-586e-426d-a633-4c8a8c926769.eot?#iefix") format("eot"), url("./avenir/f401df6f-54eb-406c-b46a-90b9451c598d.woff") format("woff"), url("./avenir/276fe25e-e430-4376-b33c-705dd77b3dba.ttf") format("truetype"), url("./avenir/869dc33b-3685-4d74-a51c-82b435579093.svg#869dc33b-3685-4d74-a51c-82b435579093") format("svg");
}
@font-face {
  font-family: "AvenirNextLTW01-Regular";
  src: url("./avenir/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
  src: url("./avenir/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix") format("eot"), url("./avenir/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"), url("./avenir/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype"), url("./avenir/52a192b1-bea5-4b48-879f-107f009b666f.svg#52a192b1-bea5-4b48-879f-107f009b666f") format("svg");
}
@font-face {
  font-family: "AvenirNextLTW01-Medium";
  src: url("./avenir/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix");
  src: url("./avenir/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix") format("eot"), url("./avenir/f26faddb-86cc-4477-a253-1e1287684336.woff") format("woff"), url("./avenir/63a74598-733c-4d0c-bd91-b01bffcd6e69.ttf") format("truetype"), url("./avenir/a89d6ad1-a04f-4a8f-b140-e55478dbea80.svg#a89d6ad1-a04f-4a8f-b140-e55478dbea80") format("svg");
}
@font-face {
  font-family: "Avenir Next LT W01 Demi";
  src: url("./avenir/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix");
  src: url("./avenir/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix") format("eot"), url("./avenir/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff"), url("./avenir/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format("truetype"), url("./avenir/99affa9a-a5e9-4559-bd07-20cf0071852d.svg#99affa9a-a5e9-4559-bd07-20cf0071852d") format("svg");
}
