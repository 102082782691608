.universalTextInput {
  white-space: pre-wrap;
  overflow: hidden;
}
.universalTextInputSingleLine {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  white-space: nowrap;
}
.universalTextInput[contentEditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #aaa;
}
