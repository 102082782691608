.board {
  padding: 10px 15px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-bottom-width: 3px;
  background-color: #fff;
}
.board:hover {
  background: #fcfcfc;
}
.board:hover .linkContainer {
  opacity: 1;
}
.title {
  cursor: pointer;
}
.title:hover {
  color: #4a90e2;
}
.ideaCount {
  font-size: 12px;
  color: #aaa;
  margin-left: 5px;
}
.linkContainer {
  margin-left: 5px;
  opacity: 0;
}
.link {
  padding: 0 5px;
  font-size: 12px;
  text-transform: uppercase;
  color: #aaa;
  text-decoration: underline;
  border-right: 1px solid #aaa;
  cursor: pointer;
}
.link:hover {
  color: #333;
}
.link:last-child {
  border-right: none;
}
.childrenToggle {
  margin-top: 5px;
  font-size: 12px;
  text-decoration: underline;
  color: #aaa;
  margin-bottom: 10px;
  cursor: pointer;
}
.childrenToggle.expanded {
  color: #4a90e2;
}
.childrenToggle:hover {
  color: #333;
}
.childrenToggle:hover.expanded {
  color: #4a90e2;
}
