@import "./normalize.css"
@import '../media/fonts/fonts.css'
@import "~commonStyles/variables.styl"

a
  cursor: pointer
  color color-blue

button
  cursor: pointer

body, html
  height: 100%
  margin: 0
  padding: 0
  font-family: 'AvenirNextLTW01-Medium', Aria, sans-serif

input
  font-family: 'AvenirNextLTW01-Medium', Aria, sans-serif
