.container {
  width: 100%;
}
.relationCheckBoxLabel {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 0px;
}
.hiddenRelationCheckbox {
  visibility: hidden;
}
