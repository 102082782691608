.permissionDetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.permissionDetails > * {
  margin-left: 10px;
}
.spacer {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.permissionSelect {
  min-width: 150px;
  display: inline-block;
}
.permissionOwner {
  margin-bottom: 0;
}
.permissionOwner > input {
  margin-right: 5px;
}
