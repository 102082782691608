.rounded-corners,
.textfield,
.searchInputContainer {
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.bordered,
.textfield,
.searchInputContainer {
  border: 1px solid #ccc;
}
.textfield,
.searchInputContainer {
  width: 100%;
  min-height: 30px;
  padding: 4px 10px;
  background: #fff;
}
.container {
  pointer-events: auto;
  width: 100%;
}
.loadingIcon {
  width: 1em;
  height: 1em;
  -ms-flex-item-align: center;
      align-self: center;
}
.searchInputContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 30px;
  background: transparent;
  text-align: left;
  text-shadow: none;
  position: relative;
  padding-bottom: 2px;
}
.searchInputContainer.bareContainer {
  font-size: inherit;
  line-height: inherit;
  min-height: auto;
}
.regular .searchInputContainer {
  background: #fff;
  color: #666;
}
.unstyled .searchInputContainer {
  background: inherit;
  color: inherit;
  border: none;
}
.searchInput {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  height: 32px;
  line-height: 30px;
  width: 100%;
  border: none;
  outline: none;
  color: inherit;
  background: transparent;
  white-space: pre-wrap /* CSS3 */;
  white-space: -moz-pre-wrap /* Firefox */;
  white-space: -pre-wrap /* Opera <7 */;
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word /* IE */;
  resize: none;
  white-space: nowrap;
}
.searchInput.multiline {
  line-height: inherit;
  height: inherit;
  white-space: pre-wrap;
  max-height: 300px;
}
.searchInput.multiline:empty {
  line-height: 32px;
  height: 32px;
  min-height: 32px;
  overflow: hidden;
}
.searchInput.bareInput {
  line-height: inherit;
  height: inherit;
}
.searchBarXButton {
  -webkit-box-flex: 0;
      -ms-flex: 0 0;
          flex: 0 0;
  line-height: 30px;
  width: 42px;
  text-align: center;
  font-size: 24px;
  color: #aaa;
  font-family: Arial, Sans-Serif;
  background: transparent;
  opacity: 0.6;
  cursor: pointer;
  position: relative;
}
.autocompleteWrapper {
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
  -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  -ms-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  -o-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border: 1px solid #ccc;
  border-top: none;
  background-color: #fff;
}
.autocompleteWrapper .controls {
  background-color: #ebebeb;
}
.autocompleteWrapper .addRelatedButton {
  background-color: #ddd;
  border: 1px solid #ccc;
  font-size: 12px;
  line-height: inherit;
  margin: 5px 15px 5px;
}
.autocompleteWrapper .addRelatedButton:hover {
  background-color: #ccc;
}
.autocompleteWrapper .addRelatedButton:active,
.autocompleteWrapper .addRelatedButton:focus {
  background-color: #bbb;
}
.searchResult {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 30px;
  border: none;
  padding-left: 14px;
  padding-right: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: sans-serif;
  border-top: 1px solid #eee;
  background-color: #fff;
  color: #666;
}
.selectedSearchResult {
  background-color: #89c7e4;
  color: #fff;
  color: #666;
}
.header {
  background-color: #aaa;
  color: #fff;
}
.match {
  background-color: #646464;
  color: #fff;
}
.recentSearchesHeader {
  padding-left: 14px;
  padding-right: 14px;
  font-size: 11px;
  text-transform: uppercase;
  background-color: #eee;
  color: #999;
  border-bottom: 1px solid #ddd;
}
