.modalWrapper
  display: flex !important
  align-items: center
  justify-content: center

.modalContent
  max-height: 90vh
  max-width: 90vw
  overflow: auto

.modalContentFixedSize
  height: 80vh !important
  width: 80vw !important

:global(.modal-dialog)
  max-width: initial !important

:global(.modal-body)
  display: flex

// https://github.com/reactstrap/reactstrap/issues/595
:global(.modal-dialog.modal-full)
  width: 100% !important
  max-width: 100% // Needed to override default 500px max-width
  margin: 0
  position: absolute
  top: 0
  bottom: 0
  display: flex

  :global(.modal-content)
    flex: 1
    display: flex
    max-width: 100% !important
    max-height: 100% !important
    border-radius: 0
    border: 0

// Don't allow modal header or footer to shrink
:global(.modal-header), :global(.modal-footer)
  flex-shrink 0

// Make the modal body scrollable independently of the header and footer
:global(.modal-body)
  overflow: auto