@import "~commonStyles/common.styl"

.outer
  max-width 700px
  min-width @width
  margin 50px auto 0
  background-color white
  border-radius(10px)
  box-shadow(0px 2px 4px #afafaf)

.inner
  min-width @width
  margin 0 auto
  padding 50px 30px 70px
  color color-text-light

@media(max-width: mobile-max-width)
  .inner
    padding 50px 10px 70px

.inner h1
  margin 10px 0
  text-align center
  color color-blue
  font-size 2em
  font-weight normal

.inner h3
  margin 10px 0
  text-align center
  color color-text-light
  font-size 1.2em
  font-weight normal

.alt
  margin-top 25px
