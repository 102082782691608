.permissionsList
  flex-grow: 1
  list-style-type: none
  padding: 0
  margin: 0

.permissionsEntry
  display: flex
  align-items: center
  flex-wrap: wrap

  > *
    margin-bottom: 10px;


.addPermissionIcon
  margin-right: 10px