@import '~commonStyles/button.styl'

.modal
  width: 700px
  padding-bottom: 55px
  margin: 0
  max-width: 100%

.emailSignupModal
  padding-top: 10px

.header
  font-weight: bold

.loginForm
  padding: 20px 50px 0px 50px
  overflow: auto

.loginInput
  box-sizing: border-box;
  font-size: 16px;
  border: solid 1px #b3b3b3;
  border-radius: 2px;
  display: block;
  appearance: none;
  width: 100%
  max-width: 500px;
  color: #000;
  padding: 10px 11px;
  height: 44px;

.emailSignupInput
  max-width: none
  margin-bottom: 5px

.passwordLoginInput
  max-width: none

.loginButton
  @extend .button
  width 100%
  font-weight bold
  color white
  background-color color-green
  margin-top: 10px

.facebookButton
  @extend .button
  width 100%
  font-weight bold
  color white
  background-color color-blue
  margin-top: 15px
  margin-bottom: 15px

.otherOptions
  position: relative
  padding-top: 10px
  text-align: center
  font-size: 14px

.splitLeftInput
  float: left
  width: 49%
  margin-right: 1%

.splitRightInput
  float: right
  margin-left: 1%
  width: 49%

.toggleText
  float: right
  overflow: auto

.backButton
  margin-left: 10px

.error
  color: color-error

.accountMergeMessage
  clear both
  margin-top 15px
  color color-text-light
  padding-bottom 5px
  span
    color color-blue

.radioButtonContainer
   margin-right 10px
   display inline-block

[type="radio"]
  margin-right 5px