@import "~commonStyles/variables.styl"

.userTableRow
  display: flex
  flex-direction: row
  position: relative

  .deleteIcon
    display: none

  &:hover
    .deleteIcon
      display: initial
      color: black
    
    .rowIcon
      color: black

.rowIcon
  color: color-lighter-gray
  font-size: 22px
  line-height: inherit
  padding-left: 5px
  opacity: 1

.rowIcons
  width: 60px
  padding-left: 5px
  flex: none
  display: flex
  align-items: center
  flex-direction: row

avatarSize = 32px
.avatarIcon
  width: avatarSize
  font-size: avatarSize
  height: avatarSize
  box-sizing: content-box
  color: color-lighter-gray
  cursor: pointer
  align-self: center

.emptyIcon
  opacity: 0
  line-height: 0px

.userTableCell.userTableHeaderCell
  border: none
  border-bottom: 3px solid #4CBBFF
  font-weight: bold
  text-align: center
  line-height: 40px
  justify-content: center
  color: #666

.userTableCell.userTableHeaderCell
  background-color: color-lighter-gray
  border-color: color-lighter-gray
  font-weight: bold
  text-align: center
  line-height: 40px
  justify-content: center

.userTableCell
  flex: 1 1 0
  padding: 5px 10px
  overflow: hidden
  line-height: 30px

  display: flex
  align-items: flex-start
  flex-direction: column

  background-color: color-background
  border-left: 1px solid color-lighter-gray
  border-bottom: 1px solid color-lighter-gray

  input, textarea, div
    outline: none
    border: none
    width: 100%

.privateField
  font-style: italic
  color: color-medium-gray

.userTableCell:nth-child(5)
  border-right: 1px solid color-lighter-gray

.userTableCell.userTableHeaderCell:nth-child(5)
  border-right: none

.userTableCell.editDisabled
  background-color: color-lightest-gray

  &.privateField
    color: color-darker-gray

.userTableCell.hyperlink
  input, textarea, div
    color: color-link
    text-decoration: underline

.userTableCell.preview
  font-style: italic
  color: color-dark-gray

.showMoreLink
  color: color-blue


@media(max-width: mobile-max-width)
  mobileAvatarSize = 90px
  
  .userTableRow
    margin: 10px 0px
    display: grid
    grid-template-columns: "calc(%s + 20px) 1fr 1fr 60px" % mobileAvatarSize
    grid-template-rows: auto auto auto 1fr
    grid-template-areas:  "avatar name    bio buttons"\
                          "avatar email   bio buttons"\
                          "avatar website bio buttons"\
                          "avatar . bio buttons"


    background-color: color-background

  .userTableRow.editDisabled
    background-color: color-lightest-gray

  .avatarIcon
    margin-left: 20px
    justify-self: center
    grid-area: avatar
    width: mobileAvatarSize
    height: mobileAvatarSize
    font-size: mobileAvatarSize

  .nameArea
    grid-area: name
    font-size: 1.3em

  .emailArea
    grid-area: email

  .websiteArea
    grid-area: website

  .websitePlainCell
    padding 4px 10px

  .bioArea
    grid-area: bio

  .bioPlainCell
    height: 100%

  .rowIcons
    grid-area: buttons

  .userTableHeaderRow
    display: none

  .userTableCell
    padding: 0px 5px
    border: none

  .userTableCell.userTableHeaderCell
    display: none

  .userTableCell:nth-child(5)
    border-right: none


  .deleteIcon
    display: initial !important