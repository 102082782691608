.userTable:focus {
  outline: none;
}
.userTable {
  margin-bottom: 50px;
}
.windowScrollerWrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.stickyUserTableRows {
  padding-top: 20px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: calc(54px - 1px);
  background-color: #fafafa;
}
@media (max-width: 1024px) {
  .stickyUserTableRows {
    top: 0px;
    padding-top: 0px;
  }
}
